.site-content {
   display: flex;
   flex-direction: column;
   margin: auto;
}
.footer{
    display: flex;
    width: 100vw;
    height: 100px;
}
.container{
    width: 100%;
    // max-width: 1128px;
	margin: 0 auto;
    position: relative;
}
.blink {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: .2s ease;
    // backdrop-filter: blur(10px);
}
/* .ant-form-item {
margin-bottom: 0 !important;
} */
.blink-hidden {
    transition: .2s ease;
    opacity: 0;
    z-index: 0;
    position: relative;
}
.blink-text {
    position: fixed;
    top: 42%;
    left: 45%;
}
.logoAuth{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 200px;
}
.signinForm{
    max-width: 620px;
    width: 80%;
    display: flex;
    margin: 20px auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 20px;
    -webkit-box-shadow: 10px 10px 101px -37px rgba(45,175,231,0.26);
    -moz-box-shadow: 10px 10px 101px -37px rgba(45,175,231,0.26);
    box-shadow: 10px 10px 101px -37px rgba(45,175,231,0.26);
    &_box{
        width: 100%;
        max-width: 330px;
        margin-bottom: 20px;
    }
    &_sub{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
}
.formLine{
    width: 100%;
    display: flex;
    margin-left: 20px;
}
.formContainer{
    // max-width: 620px;
    width: 100%;
    display: flex;
    margin: 20px auto;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 20px;
    &_box{
        width: 100%;
        max-width: 330px;
        margin-bottom: 20px;
    }
    &_sub{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
}
.accountData{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    &_title{
        color: #959595;
    }
}

.w25p{
    max-width: 25%;
}

.flexRow{
    flex-direction: row !important;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
}

.inpage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    &_box{
        width: 100%;
        max-width: 500px;
    }
    &_boxUneditable{
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }
}
.divider{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #D3DDEC;
}
.center{
    display: flex;
    align-items: center;
    h1{
        text-align: center;
    }
}
.rbuttonSec{
    position: absolute;
    top: 550px;
    right: 0;
    width: 100%;
    max-width: 520px;
    &_but{
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
    }
}
.rbuttonSecMobile{
    width: 100%;
    max-width: 520px;
    &_but{
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
    }
}
.textBut{
    color: #2DAFE7;
    text-decoration: underline;
    cursor: pointer;
}
.mt20{
    margin-top: 20px !important;
}
.mb20{
    margin-bottom: 20px;
}
.w50{
    max-width: 50%;
}
.note{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #959595;
    &_button{
        text-decoration: underline;
        color: #2DAFE7;
        font-size: 16px;
        text-align: right;
        cursor: pointer;
        padding-top: 5px;
    }
}
.label{
    color: #959595;
}
.acc{
    &_title{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        svg {
            height: 22px;
            width: 22px;
            margin-left: auto;
            cursor: pointer;
        }
    }
}

.accBox{
    display: flex;
    width: 100%;
    border: 1px solid #D3DDEC;
    flex-direction: column;
    margin-bottom: 20px;
    &_head{
        border-bottom: 1px solid #D3DDEC;
        font-weight: 600;
        padding: 10px 8px !important;
    }
    &_line{
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        span{
            display: flex;
            width: 50%;
            justify-content: flex-start;
            word-break: break-all;
        }
        svg {
            height: 20px;
            width: 20px;
            margin-left: auto;
            cursor: pointer;
        }
    }
}
.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  background: white;
  outline: none;
  max-width: calc(100vw - 1rem);
  max-height: calc(100vh - 1rem);
  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.24);
  overflow-y: auto;
  position: relative;
}
.dashboard{
    &_menu{
        display: flex;
        align-items: center;
        text-transform: uppercase;
        svg{
            fill: #A1AEBD;
            //stroke: #A1AEBD;
            margin-right: 10px;
        }
    }
    &--active{
        svg{
            fill: #333;
            //stroke: #333;
            margin-right: 10px;
        }
    }
}
.modalHeader{
    display: flex;
    justify-content: space-between;
    div{
        display: flex;
        font-weight: 600;
        font-size: 35px;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        color: #333;
        margin-top: -15px;
    }
}
.centred{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ant-tabs-tab {
    text-transform: uppercase;
    font-size: 18px !important;
    font-weight: 600;
    color: #A1AEBD;
    &:hover{
        color: #333 !important;
    }
    // border-right: 1px solid #A1AEBD !important;
    padding-right: 20px !important;
}

/* .ant-tabs-tab::after {
    content: " ";
    border: 2px solid red;
    &:last-child {
        border: none !important;
    }
} */

/* .ant-tabs-tab.ant-tabs-tab-active {
    border-right: none !important;
} */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important; 
    font-weight: 600;
    // border-right: none !important;
 }

.ant-tabs-ink-bar::after {
    content: " ";
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    height: 2px;
    background: #333;
    width: 100%;
    transform: translateX(-50%);
  }

/* .ant-form-item {
    width: 80%;
} */

.ant-form-item{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 10px !important;
}

.fieldBox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.ant-picker .ant-picker-focused{
    outline: 0 !important;
    outline-offset: 0 !important;
}
.ant-select-selector{
    background-color: transparent !important;
}
.bgImage {
    display: flex;
    height: 450px;
    width: 300px;
    transform: rotate(30deg);
    top: -120px;
    right: 115px;
    position: absolute;
    z-index: -10;
    background: rgb(186,232,255);
    background: linear-gradient(164deg, rgba(186,232,255,0.5) 25%, rgba(0,212,255,0) 70%);
}

.filters{
    display: flex;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 50px !important;
    margin-top: 30px !important;
    align-items: center;
    &_butt{
        display: flex;
        width: 100%;
        grid-gap: 20px;
        margin-top: 18px;
    }
}
.photoBox{
    // border: 1px solid #959595;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
}

.maskImage{
    display: flex;
    background-color: red;
}
.statusEmployee{
    border: 1px solid #ff9470;
    border-radius: 5px;
    padding: 3px 10px;
    max-height: 24px;
    &--active{
        background-color: #c8f7c5 ;
        border: 1px solid #3fc380;
        color: #3fc380;
        svg{
            stroke: #3fc380;
            fill: #3fc380;
        }
    }
    &--disactive{
        background-color: #ffe4e4 ;
        border: 1px solid #c44d56;
        color: #c44d56;
        svg{
            stroke:#c44d56;
            fill: #c44d56;
        }
    }
}
.ant-layout-sider-trigger{
    border-right: 1px solid #959595 !important;
}
.activity{
    border: 1px solid #ff9470;
    border-radius: 5px;
    padding: 3px 10px;
    &--NEW{
        background-color: #fdf6f1;
        color: #ff9470;
        svg{
            stroke:#ff9470;
            fill: #ff9470;
        }
    }
    &--INPROGRESS{
        background-color: #ffffcc ;
        border: 1px solid #f9b42d;
        color: #f9b42d;
        svg{
            stroke:#f9b42d;
            fill: #f9b42d;
        }
    }
    &--REJECTED{
        background-color: #ffe4e4 ;
        border: 1px solid #c44d56;
        color: #c44d56;
        svg{
            stroke:#c44d56;
            fill: #c44d56;
        }
    }
    &--EXPIRED{
        background-color: #ffe4e4 ;
        border: 1px solid #c44d56;
        color: #c44d56;
        svg{
            stroke:#c44d56;
            fill: #c44d56;
        }
    }
    &--PENDING{
        background-color: #ffffcc ;
        border: 1px solid #f9b42d;
        color: #f9b42d;
        svg{
            stroke:#f9b42d;
            fill: #f9b42d;
        }
    }
    &--DONE{
        background-color: #c8f7c5 ;
        border: 1px solid #3fc380;
        color: #3fc380;
        svg{
            stroke: #3fc380;
            fill: #3fc380;
        }
    }
    &--CONFIRMED{
        background-color: #c8f7c5 ;
        border: 1px solid #3fc380;
        color: #3fc380;
        svg{
            stroke: #3fc380;
            fill: #3fc380;
        }
    }
    &--EXECUTED{
        background-color: #c8f7c5 ;
        border: 1px solid #3fc380;
        color: #3fc380;
        svg{
            stroke: #3fc380;
            fill: #3fc380;
        }
    }
}
 // TODO REPLCAE
.ant-input-affix-wrapper{
    width: 100%;
    border: none !important;
    background: #F7F9FB !important;
    height: 40px;
    box-sizing: border-box;
    // padding: 12px 14px 12px 14px;
    letter-spacing: -0.10000000149011612px;
    text-align: left;
    &:active, &:hover, &:focus {
        outline: 0  !important;
        outline-offset: 0 !important;
    }
    box-sizing: border-box;
    transition: .2s;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    input[type='number'] {
    -moz-appearance: textfield;
    }
}
.ant-input, .ant-input-password{
    background: #F7F9FB !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 28px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    &:active, &:hover, &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
    }
}
.ant-input-focused{
        outline: 0 !important;
        outline-offset: 0 !important;
}
.ant-input-group{
    width: 100% !important;
    border: none !important;
    background: #F7F9FB !important;
    height: 50px;
    box-sizing: border-box;
    padding: 13px 16px 13px 16px; 
}
