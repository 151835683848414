.header{
    display: flex;
    justify-content:flex-end;
    box-sizing: border-box;
    // width: 1828px;
    // max-width: 1182px;
    padding: 10px 20px;
    &_menu{
        display: flex;
        gap: 30px;
    }
}

.langIcon{
    display: flex;
	cursor: pointer;
	svg {
		height: 25px;
		width: 25px;
	}
}
.accountMenu{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    &_name{
        color: #959595;
        cursor: default !important;
    }
    span{
        padding: 10px 5px;
        cursor: pointer;
    }
}
.tab_style{
    .ant-tabs-tab {
        text-transform: uppercase;
        font-size: 14px !important;
        font-weight: 400;
        color: #A1AEBD;
        &:hover{
            color: #1890ff !important;
        }
        // border-right: 1px solid #A1AEBD !important;
        //padding-right: 20px !important;
    }
    .ant-tabs-ink-bar::after {
        content: " ";
        position: absolute;
        left: 50%;
        right: 0;
        bottom: 0;
        height: 2px;
        background: #1890ff;
        width: 100%;
        transform: translateX(-50%);
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1890ff !important; 
        font-weight: 400;
        // border-right: none !important;
     }
}