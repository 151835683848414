.webcamContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        margin: 30px;
    }
}
.webcamButtons{
    display: flex;
    gap: 20px;
}
.accountModal{
    &_title{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &_nameModal{
        display: flex;
        font-weight: 600;
        padding-right: 10px;
    }
    &_nameAcc{
        color: #959595;
    }
    &_name{
        display: flex;
        align-items: center;
    }
    &_verifiedBox{
        display: flex;
        gap: 10px;
        font-size: 14px;
        max-height: 24px;
        align-items: center;
        border-radius: 5px;
        padding: 3px 10px;
            &--warning{
            background-color: #ffe4e4 ;
            border: 1px solid #c44d56;
            color: #c44d56;
            svg{
                stroke:#c44d56;
                fill: #c44d56;
            }
        }
        &--pending{
            background-color: #fdf6f1 ;
            border: 1px solid #ff9470;
            color: #ff9470;
            svg{
                stroke:#ff9470;
                fill: #ff9470;
            }
        }
        &--in_progress{
            background-color: #ffffcc ;
            border: 1px solid #f9b42d;
            color: #f9b42d;
            svg{
                stroke:#f9b42d;
                fill: #f9b42d;
            }
        }
        &--complited{
            background-color: #c8f7c5 ;
            border: 1px solid #3fc380;
            color: #3fc380;
            svg{
                stroke: #3fc380;
                fill: #3fc380;
            }
        }
    }
    &_startVerification{
        margin-left: 10px;
        
    }
}
.bankAccount{
    border: 1px solid #2DAFE7;
    &_header{
        background-color: #2DAFE7;
        padding: 5px 10px;
        font-weight: 600;
        color: #fff;
    }
}

.switcherBox{
   display: flex;
   flex-direction: column;
   height: 100%;
   padding: 10px;   
}

.accBox{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px dotted #ebebeb;
    padding: 10px;
    margin-top: -10px;
    padding-bottom: 0px;
    border-radius: 5px;
    font-size: 12px;
}

.logoSideBar {
    height: 32px;
    margin: 25px;
}