.inputBox{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
}
.inp{
    &:active, &:hover, &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
    }
}
.noteEmailCodeSend{
    font-size: 10px;
}